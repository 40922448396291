import { Icon, Image } from "@chakra-ui/react";
import CircleSvg from "./Group.svg";
export function LogoHero() {
  return (
    <Image src={CircleSvg} width={{ base: "70%", md: "70%", xl: "70%" }} />
  )
  //   return (
  //     <Icon  w="1440" h="676">
  // <svg width="465" height="474" viewBox="0 0 465 474" fill="none" xmlns="http://www.w3.org/2000/svg">
  // <path d="M453.633 460.837L462.873 69.6243C463.043 62.4523 457.273 56.5473 450.093 56.5493L271.453 56.6093C265.253 56.6093 260.163 62.3523 259.853 69.6843L243.653 458.879C243.533 461.774 244.163 464.562 245.443 466.942C249.403 469.214 252.563 471.034 256.513 473.307H440.863C447.803 473.307 453.473 467.769 453.633 460.834V460.837Z" fill="#292C56"/>
  // <path d="M243.953 473.309H426.413C433.353 473.309 439.013 467.744 439.183 460.772L448.553 69.7474C448.733 62.5324 442.963 56.5894 435.783 56.5894H260.143C253.303 56.5894 247.673 62.0224 247.383 68.9024L231.193 459.927C230.893 467.227 236.693 473.309 243.963 473.309H243.953Z" fill="#DCE7EA"/>
  // <path d="M407.873 267.646H271.593C271.043 267.646 270.593 267.199 270.593 266.646C270.593 266.094 271.043 265.646 271.593 265.646H407.873C408.423 265.646 408.873 266.094 408.873 266.646C408.873 267.199 408.423 267.646 407.873 267.646Z" fill="white"/>
  // <path d="M407.873 285.101H271.593C271.043 285.101 270.593 284.654 270.593 284.101C270.593 283.549 271.043 283.101 271.593 283.101H407.873C408.423 283.101 408.873 283.549 408.873 284.101C408.873 284.654 408.423 285.101 407.873 285.101Z" fill="white"/>
  // <path d="M407.873 302.559H271.593C271.043 302.559 270.593 302.112 270.593 301.559C270.593 301.007 271.043 300.559 271.593 300.559H407.873C408.423 300.559 408.873 301.007 408.873 301.559C408.873 302.112 408.423 302.559 407.873 302.559Z" fill="white"/>
  // <path d="M407.873 320.016H271.593C271.043 320.016 270.593 319.569 270.593 319.016C270.593 318.464 271.043 318.016 271.593 318.016H407.873C408.423 318.016 408.873 318.464 408.873 319.016C408.873 319.569 408.423 320.016 407.873 320.016Z" fill="white"/>
  // <path d="M407.873 337.471H271.593C271.043 337.471 270.593 337.024 270.593 336.471C270.593 335.919 271.043 335.471 271.593 335.471H407.873C408.423 335.471 408.873 335.919 408.873 336.471C408.873 337.024 408.423 337.471 407.873 337.471Z" fill="white"/>
  // <path d="M407.873 354.929H271.593C271.043 354.929 270.593 354.482 270.593 353.929C270.593 353.377 271.043 352.929 271.593 352.929H407.873C408.423 352.929 408.873 353.377 408.873 353.929C408.873 354.482 408.423 354.929 407.873 354.929Z" fill="white"/>
  // <path d="M457.003 158.21L458.533 158.259C460.853 158.333 462.793 156.514 462.873 154.195L463.933 120.887C464.003 118.569 462.183 116.629 459.873 116.555L458.332 116.506C456.012 116.432 454.073 118.252 454.003 120.57L452.942 153.878C452.862 156.196 454.683 158.136 457.003 158.21Z" fill="white"/>
  // <path d="M458.683 159.221C458.563 159.221 458.573 159.221 456.983 159.169C454.373 159.169 451.853 156.849 451.953 153.806L453.023 120.499C453.113 117.656 455.473 115.381 458.393 115.469L459.923 115.519C462.783 115.609 465.043 118.016 464.953 120.881L463.883 154.189C463.793 156.999 461.483 159.221 458.693 159.221H458.683ZM457.053 157.171L458.583 157.221C460.333 157.279 461.823 155.889 461.883 154.124L462.953 120.816C463.013 119.054 461.623 117.571 459.853 117.516C458.263 117.464 457.173 117.256 456.033 118.331C455.413 118.916 455.053 119.709 455.023 120.564L453.953 153.871C453.893 155.729 455.423 157.171 457.053 157.171Z" fill="#292C56"/>
  // <path d="M455.493 205.076L457.033 205.125C459.353 205.199 461.293 203.38 461.363 201.061L462.423 167.753C462.503 165.435 460.683 163.496 458.363 163.421L456.833 163.372C454.513 163.298 452.573 165.118 452.493 167.436L451.433 200.744C451.363 203.063 453.183 205.002 455.493 205.076Z" fill="white"/>
  // <path d="M457.183 206.087C457.063 206.087 457.073 206.087 455.483 206.034C452.833 206.034 450.353 203.667 450.453 200.669L451.523 167.362C451.563 165.974 452.143 164.687 453.163 163.737C454.943 162.064 456.713 162.327 458.413 162.382C461.283 162.472 463.543 164.879 463.443 167.744L462.383 201.052C462.293 203.862 459.973 206.084 457.183 206.084V206.087ZM455.543 204.037L457.083 204.087C458.833 204.139 460.323 202.754 460.383 200.989L461.453 167.682C461.503 165.919 460.113 164.437 458.353 164.382L456.823 164.332C455.073 164.269 453.573 165.664 453.523 167.429L452.453 200.737C452.393 202.594 453.933 204.039 455.553 204.039L455.543 204.037Z" fill="#292C56"/>
  // <path d="M301.923 70.3763H266.563C264.203 70.3763 262.293 72.2894 262.293 74.6494V76.2074C262.293 78.5664 264.203 80.4793 266.563 80.4793H301.923C304.283 80.4793 306.193 78.5664 306.193 76.2074V74.6494C306.193 72.2894 304.283 70.3763 301.923 70.3763Z" fill="#292C56"/>
  // <path d="M398.603 212.697H279.573C272.153 212.697 266.133 218.716 266.133 226.142V228.052C266.133 235.477 272.153 241.497 279.573 241.497H398.603C406.033 241.497 412.053 235.477 412.053 228.052V226.142C412.053 218.716 406.033 212.697 398.603 212.697Z" fill="#292C56"/>
  // <path d="M367.353 374.594H302.853C295.423 374.594 289.413 380.614 289.413 388.039V389.949C289.413 397.375 295.423 403.394 302.853 403.394H367.353C374.783 403.394 380.803 397.375 380.803 389.949V388.039C380.803 380.614 374.783 374.594 367.353 374.594Z" fill="#98BA80"/>
  // <path d="M389.683 401.734L338.593 385.671L338.403 385.871L355.043 436.774L366.563 425.126L381.813 440.209L393.423 428.461L378.173 413.381L389.683 401.734Z" fill="white"/>
  // <path d="M381.113 440.919L366.573 426.541L355.753 437.476C355.233 438.004 354.333 437.799 354.093 437.084L337.453 386.181C337.333 385.826 337.423 385.434 337.693 385.169C337.903 384.954 338.273 384.516 338.903 384.719L389.993 400.781C390.703 401.006 390.923 401.906 390.403 402.439L379.583 413.374L394.133 427.751C394.523 428.141 394.523 428.774 394.133 429.166L382.523 440.911C382.133 441.304 381.503 441.309 381.113 440.919ZM367.263 424.416L381.803 438.794L392.013 428.469L377.473 414.091C377.073 413.701 377.073 413.069 377.463 412.676L387.823 402.194L339.863 387.114L355.483 434.904L365.853 424.421C366.233 424.029 366.873 424.024 367.263 424.414V424.416Z" fill="#292C56"/>
  // <path d="M147.201 418.139L139.731 472.892H246.803C246.803 472.892 248.553 448.737 224.393 445.517C200.241 442.294 183.061 418.142 183.061 418.142H147.203L147.201 418.139Z" fill="#292C56"/>
  // <path d="M99.6327 418.139C98.6427 436.389 97.6527 454.642 96.6657 472.892H0.0276868C0.0276868 472.892 -1.71434 448.737 22.4377 445.517C46.5927 442.294 63.7727 418.142 63.7727 418.142H99.6307L99.6327 418.139Z" fill="#292C56"/>
  // <path d="M64.8197 370.679L64.3197 420.119H98.8797L104.5 370.679H64.8197Z" fill="white"/>
  // <path d="M98.8796 421.119H64.3196C63.7626 421.119 63.3146 420.662 63.3196 420.109L63.8196 370.669C63.8246 370.119 64.2726 369.679 64.8196 369.679H104.5C105.098 369.679 105.56 370.199 105.493 370.792L99.8746 420.232C99.8176 420.737 99.3896 421.119 98.8826 421.119H98.8796ZM65.3296 419.119H97.9876L103.378 371.679H65.8076L65.3296 419.119Z" fill="#292C56"/>
  // <path d="M146.205 372.919L147.605 420.119H182.165L184.82 372.919H146.205Z" fill="white"/>
  // <path d="M182.165 421.119H147.605C147.065 421.119 146.623 420.689 146.605 420.149L145.205 372.949C145.188 372.387 145.643 371.919 146.205 371.919H184.818C185.388 371.919 185.848 372.399 185.815 372.974L183.163 420.174C183.133 420.704 182.695 421.119 182.165 421.119ZM148.578 419.119H181.223L183.763 373.919H147.238L148.578 419.119Z" fill="#292C56"/>
  // <path d="M84.2047 154.386C84.2047 154.386 69.4376 167.799 64.3226 206.199C59.2046 244.599 50.8696 405.239 50.8696 405.239H118.728C120.648 342.146 122.568 279.051 124.488 215.959C131.053 244.491 137.615 273.026 144.18 301.559L135.645 405.239H201.61C201.61 405.239 203.85 301.559 198.41 273.399C192.97 245.239 167.725 162.289 167.725 162.289L84.2096 154.386H84.2047Z" fill="white"/>
  // <path d="M201.603 406.239H135.638C135.05 406.239 134.593 405.736 134.64 405.156L143.163 301.631L125.245 223.739L119.72 405.269C119.703 405.809 119.26 406.239 118.72 406.239H50.8627C50.2877 406.239 49.8347 405.756 49.8647 405.186C49.9477 403.579 58.2627 244.066 63.3247 206.066C65.9877 186.084 71.3027 172.934 75.2947 165.424C79.6577 157.211 83.3727 153.789 83.5277 153.646C83.7347 153.459 84.0097 153.366 84.2947 153.391L167.81 161.294C168.215 161.331 168.555 161.611 168.673 161.999C168.925 162.829 193.995 245.321 199.383 273.209C204.783 301.151 202.623 404.221 202.6 405.259C202.588 405.804 202.145 406.236 201.6 406.236L201.603 406.239ZM136.725 404.239H200.623C200.83 393.591 202.498 299.866 197.42 273.589C192.305 247.106 169.32 171.024 166.955 163.224L84.5727 155.429C83.6127 156.414 80.5027 159.861 76.9947 166.491C73.1077 173.844 67.9227 186.726 65.3097 206.331C60.4727 242.631 52.6527 390.264 51.9197 404.239H117.753L123.483 215.929C123.518 214.759 125.195 214.606 125.458 215.734L145.15 301.334L136.725 404.239Z" fill="#292C56"/>
  // <path d="M168.926 130.407C169.928 136.434 171.293 141.192 173.408 145.374C192.063 182.292 269.773 176.517 279.813 175.669L284.243 153.474C259.543 145.784 234.843 138.094 210.143 130.407C209.833 128.257 209.483 125.869 209.103 123.309L168.928 130.407H168.926Z" fill="white"/>
  // <path d="M172.513 145.826C170.41 141.666 169 136.961 167.938 130.571C167.848 130.026 168.215 129.511 168.76 129.421C169.31 129.326 169.82 129.699 169.91 130.244C170.938 136.426 172.29 140.954 174.298 144.924C181.975 160.116 200.705 169.796 229.973 173.694C250.803 176.471 270.673 175.404 278.973 174.736L283.083 154.164L209.833 131.364C209.473 131.249 209.203 130.931 209.143 130.551C208.833 128.404 208.493 126.016 208.113 123.456C208.033 122.911 208.403 122.401 208.953 122.321C209.503 122.249 210.003 122.616 210.083 123.164C210.433 125.481 210.743 127.656 211.033 129.641L284.543 152.521C285.033 152.674 285.323 153.171 285.223 153.674L280.783 175.869C280.693 176.306 280.333 176.631 279.883 176.669C245.683 179.559 188.165 176.804 172.513 145.829V145.826Z" fill="#292C56"/>
  // <path d="M214.143 122.572C212.383 117.427 209.853 110.149 206.663 101.474C196.958 75.1043 192.006 61.8223 186.906 56.9573C180.616 50.9573 175.401 50.9643 131.403 48.0493C86.4577 45.0723 77.4977 44.0393 70.2977 51.3943C64.7857 57.0243 63.4777 66.2773 61.0907 84.5523C58.5727 103.817 58.4107 119.967 58.7607 131.267C64.6627 131.739 70.5627 132.214 76.4657 132.687C70.3107 153.439 64.1527 174.192 57.9977 194.944C98.8807 201.399 141.261 204.447 184.668 203.512C181.488 178.802 178.308 154.094 175.128 129.384C188.133 127.114 201.136 124.842 214.143 122.572Z" fill="#98BA80"/>
  // <path d="M353.293 179.662L315.843 238.964L257.663 202.224C251.013 198.024 245.203 192.987 240.303 187.337C212.383 155.182 254.383 110.107 288.363 135.767L293.023 139.284C294.243 137.082 295.453 134.879 296.663 132.677C317.103 95.5523 373.853 118.724 362.293 159.502C360.333 166.452 357.343 173.239 353.283 179.659L353.293 179.662Z" fill="#98BA80"/>
  // <path d="M140.09 25.6724L138.988 25.4634C134.711 24.6514 130.585 27.4604 129.773 31.7374L126.279 50.1414C125.467 54.4184 128.276 58.5434 132.553 59.3554L133.656 59.5644C137.933 60.3764 142.058 57.5684 142.87 53.2914L146.364 34.8874C147.176 30.6104 144.367 26.4844 140.09 25.6724Z" fill="white"/>
  // <path d="M135.128 60.7023C134.12 60.7023 133.513 60.5543 132.368 60.3373C127.555 59.4223 124.383 54.7643 125.298 49.9523L128.793 31.5493C129.71 26.7193 134.363 23.5623 139.178 24.4793L140.278 24.6893C145.09 25.6043 148.263 30.2623 147.348 35.0743L143.855 53.4773C143.048 57.7323 139.313 60.7043 135.13 60.7043L135.128 60.7023ZM137.503 26.3223C134.3 26.3223 131.388 28.5923 130.755 31.9243L127.26 50.3273C126.553 54.0543 129.01 57.6673 132.738 58.3743L133.84 58.5843C137.565 59.2923 141.178 56.8343 141.888 53.1073L145.38 34.7043C146.088 30.9773 143.63 27.3673 139.903 26.6593C138.803 26.4523 138.303 26.3273 137.503 26.3273V26.3223Z" fill="#292C56"/>
  // <path d="M155.608 24.0513C155.766 21.4213 154.873 18.8363 153.136 16.8593C151.448 14.9363 148.916 12.7913 145.436 11.6713C143.848 11.1613 140.336 10.0513 136.186 11.3693C135.973 11.4363 131.913 12.7763 129.436 16.0963C128.478 17.3793 127.823 18.8843 127.448 20.5313C125.943 20.2313 124.476 20.5993 123.561 21.6593C121.236 24.3563 123.993 29.2213 128.031 29.7243C129.326 33.8263 131.223 36.5393 133.671 38.6213C136.326 40.8793 141.028 44.1443 146.153 44.2893C153.341 44.4893 154.921 35.6213 155.611 24.0543L155.608 24.0513Z" fill="white"/>
  // <path d="M146.401 45.2893C141.541 45.2893 136.723 42.5293 133.021 39.3793C130.556 37.2813 128.646 34.6193 127.266 30.5993C124.626 29.9893 122.333 27.7443 121.821 25.0843C121.188 21.7943 123.521 19.2793 126.698 19.4343C127.131 17.9613 127.781 16.6393 128.633 15.4963C131.321 11.8963 135.696 10.4713 135.881 10.4143C141.308 8.69631 148.711 10.3043 153.888 16.1963C155.816 18.3913 156.783 21.2013 156.608 24.1113C155.968 34.8343 154.643 45.2893 146.403 45.2893H146.401ZM123.786 24.7063C124.173 26.7313 126.121 28.4743 128.153 28.7293C129.271 28.8693 128.756 29.8663 130.681 33.4213C131.578 35.0763 132.801 36.5693 134.316 37.8563C136.631 39.8263 141.251 43.1463 146.178 43.2843C152.256 43.4693 153.891 36.0263 154.611 23.9893C154.753 21.6093 153.961 19.3113 152.383 17.5143C147.766 12.2563 141.168 10.8313 136.486 12.3163C136.448 12.3293 132.541 13.5963 130.236 16.6893C129.386 17.8263 128.776 19.1943 128.423 20.7463C128.303 21.2743 127.786 21.6143 127.253 21.5063C124.968 21.0563 123.366 22.5143 123.788 24.7043L123.786 24.7063Z" fill="#292C56"/>
  // <path d="M133.668 38.6193C133.668 38.6193 136.821 47.9293 143.716 48.8363L144.603 44.1643C144.603 44.1643 137.533 43.3413 133.668 38.6213V38.6193Z" fill="#292C56"/>
  // <path d="M70.9977 131.116L61.9727 176.496L104.848 181.166C116.235 182.406 127.075 175.996 131.478 165.421L136.068 154.389L137.663 166.009C139.128 176.691 148.483 184.506 159.255 184.046L185.175 182.939L167.493 50.6514L152.855 49.8194C152.855 49.8194 157.495 74.9394 152.535 82.4594C147.575 89.9794 127.063 98.2914 118.28 47.1764L105.81 46.3914L115.558 121.204L70.9997 131.121L70.9977 131.116Z" fill="white"/>
  // <path d="M158.333 185.064C147.395 185.064 138.17 177.084 136.67 166.144L135.575 158.169L132.398 165.804C127.858 176.714 116.483 183.442 104.738 182.159L61.8627 177.492C61.2827 177.429 60.8747 176.879 60.9897 176.302L70.0147 130.922C70.1227 130.382 70.6447 130.024 71.1927 130.137C71.7347 130.244 72.0847 130.772 71.9777 131.312L63.1647 175.619L104.953 180.169C115.823 181.357 126.348 175.129 130.55 165.034L135.143 154.002C135.533 153.064 136.918 153.244 137.058 154.249L138.653 165.869C140.055 176.102 148.905 183.489 159.213 183.044L184.038 181.982L166.61 51.5973L154.055 50.8823C154.938 56.1523 157.896 76.1373 153.368 83.0023C150.943 86.6793 145.6 90.0693 139.595 88.8093C132.473 87.3193 122.875 79.0273 117.428 48.1173L106.958 47.4573L116.55 121.067C116.623 121.614 116.235 122.117 115.688 122.187C115.145 122.259 114.638 121.874 114.568 121.324L104.82 46.5123C104.74 45.8973 105.235 45.3473 105.875 45.3843L118.345 46.1693C118.808 46.1993 119.19 46.5423 119.268 46.9993C121.633 60.7543 127.47 84.2243 140.01 86.8523C145.445 87.9893 149.98 84.5143 151.703 81.9023C156.385 74.8043 151.92 50.2423 151.875 49.9943C151.758 49.3623 152.26 48.7823 152.915 48.8143L167.553 49.6473C168.033 49.6743 168.423 50.0373 168.488 50.5123L186.17 182.799C186.248 183.384 185.808 183.907 185.223 183.932L158.34 185.062L158.333 185.064Z" fill="#292C56"/>
  // <path d="M136.533 161.537C135.955 161.537 135.503 161.052 135.533 160.484L139.245 90.7243C139.275 90.1723 139.75 89.7523 140.298 89.7773C140.85 89.8073 141.273 90.2773 141.243 90.8293L137.53 160.589C137.503 161.124 137.06 161.537 136.533 161.537Z" fill="#292C56"/>
  // <path d="M70.9977 131.116C72.4557 135.919 75.2607 143.096 80.9577 150.069C97.8957 170.794 126.941 173.196 157.653 175.736C171.701 176.899 189.696 176.761 206.613 174.776L209.493 154.386C178.293 144.064 147.093 133.739 115.893 123.416C115.776 122.669 115.668 121.939 115.556 121.199L70.9977 131.116Z" fill="white"/>
  // <path d="M157.571 176.734C126.623 174.174 97.3926 171.757 80.1856 150.704C75.6626 145.172 72.2506 138.679 70.0426 131.409C69.8826 130.879 70.1806 130.322 70.7106 130.162C71.2356 130.002 71.7976 130.299 71.9576 130.827C74.0906 137.844 77.3776 144.107 81.7356 149.437C98.4056 169.834 127.226 172.217 157.738 174.739C171.463 175.877 188.996 175.782 205.733 173.872L208.393 155.074L115.581 124.364C115.226 124.247 114.966 123.939 114.906 123.569L114.568 121.347C114.486 120.802 114.861 120.292 115.408 120.209C115.953 120.127 116.463 120.502 116.546 121.049L116.791 122.659L209.813 153.437C210.273 153.589 210.553 154.047 210.483 154.527L207.603 174.914C207.543 175.364 207.183 175.714 206.733 175.767C189.838 177.747 171.938 177.917 157.573 176.732L157.571 176.734Z" fill="#292C56"/>
  // <path d="M172.355 94.7144L145.285 98.6344L150.01 131.264L177.081 127.344L172.355 94.7144Z" fill="white"/>
  // <path d="M150.005 132.269C149.51 132.269 149.088 131.907 149.015 131.412L144.29 98.7824C144.21 98.2344 144.59 97.7294 145.138 97.6494L172.208 93.7293C172.733 93.6443 173.26 94.0173 173.34 94.5743L178.065 127.204C178.145 127.752 177.765 128.257 177.218 128.337L150.003 132.267L150.005 132.269ZM146.413 99.4874L150.85 130.139L175.943 126.504L171.505 95.8523L146.413 99.4874Z" fill="#292C56"/>
  // <path d="M146.088 98.5203L172.354 94.7163L172.465 95.4833C173.327 101.438 169.191 106.976 163.235 107.838L157.052 108.734C151.926 109.476 147.159 105.917 146.416 100.788L146.087 98.5173L146.088 98.5203Z" fill="white"/>
  // <path d="M155.68 109.834C150.663 109.834 146.18 106.161 145.423 100.934L145.095 98.6643C145.015 98.1163 145.395 97.6113 145.943 97.5313L172.208 93.7292C172.728 93.6442 173.258 94.0112 173.34 94.5762L173.45 95.3443C174.39 101.839 169.87 107.891 163.375 108.831C162.953 108.831 157.325 109.836 155.68 109.836V109.834ZM147.218 99.3692L147.403 100.649C148.068 105.239 152.313 108.406 156.905 107.746L163.088 106.851C168.418 106.079 172.15 101.171 171.5 95.8513L147.218 99.3663V99.3692Z" fill="#292C56"/>
  // <path d="M160.056 106.702C161.139 106.702 162.018 105.823 162.018 104.739C162.018 103.656 161.139 102.777 160.056 102.777C158.972 102.777 158.093 103.656 158.093 104.739C158.093 105.823 158.972 106.702 160.056 106.702Z" fill="#292C56"/>
  // <path d="M124.89 20.7193C124.89 20.7193 127.573 20.6723 128.773 22.9643C129.973 25.2573 141.893 16.5123 143.865 15.7643C145.838 15.0173 146.585 18.0573 146.585 18.0573C146.585 18.0573 148.985 16.0843 149.945 18.5373C150.905 20.9893 148.718 25.4693 148.718 25.4693L159.17 28.2973C159.17 28.2973 162.503 14.3243 161.25 10.3493C159.998 6.37732 149.73 7.09731 149.73 7.09731C149.73 7.09731 139.918 -0.74269 132.823 0.0573098C125.73 0.85731 122.715 22.0843 122.715 22.0843L124.888 20.7193H124.89Z" fill="#292C56"/>
  // <path d="M196.663 150.141L209.493 154.386C209.493 154.386 226.033 145.841 231.893 144.936C237.753 144.031 253.333 157.656 252.373 160.536C251.413 163.416 238.073 154.386 238.073 154.386C238.073 154.386 251.973 164.696 251.573 168.136C251.173 171.576 238.073 162.376 238.073 162.376C238.073 162.376 249.893 171.976 248.213 174.376C246.533 176.776 234.453 167.976 234.453 167.976C234.453 167.976 244.373 177.656 242.933 180.936C241.493 184.216 233.973 175.336 233.973 175.336C233.973 175.336 212.133 183.736 206.613 174.776L193.68 175.951L196.663 150.141Z" fill="white"/>
  // <path d="M241.903 182.662C239.353 182.662 235.393 178.489 233.673 176.517C229.913 177.867 211.923 183.794 206.113 175.827L193.773 176.947C193.223 177.004 192.738 176.592 192.685 176.042C192.635 175.492 193.04 175.004 193.59 174.954L206.523 173.779C206.903 173.739 207.263 173.929 207.463 174.252C211.483 180.772 226.573 177.092 233.613 174.404C234.013 174.252 234.463 174.369 234.733 174.692C237.913 178.447 241.213 180.972 241.943 180.657C241.943 180.657 241.973 180.629 242.013 180.537C242.733 178.904 238.283 173.119 233.753 168.694C233.383 168.329 233.353 167.734 233.693 167.332C234.023 166.929 234.613 166.859 235.043 167.169C240.493 171.144 246.183 174.189 247.383 173.784C247.493 172.502 242.913 167.592 237.443 163.154C237.023 162.814 236.953 162.204 237.273 161.774C237.603 161.344 238.213 161.247 238.653 161.559C243.913 165.257 249.233 168.062 250.583 167.922C250.473 166.367 245.563 161.187 237.483 155.192C237.043 154.869 236.943 154.254 237.263 153.812C237.573 153.369 238.183 153.257 238.633 153.562C244.313 157.407 250.023 160.314 251.413 160.119C251.293 159.529 250.113 157.549 246.733 154.409C241.853 149.879 235.023 145.469 232.053 145.927C226.413 146.797 210.123 155.194 209.953 155.279C209.713 155.402 209.433 155.424 209.183 155.339L196.355 151.094C195.83 150.922 195.545 150.354 195.72 149.832C195.893 149.307 196.458 149.019 196.983 149.197L209.413 153.309C211.973 151.999 226.213 144.804 231.743 143.952C236.993 143.144 247.363 151.599 251.223 156.172C253.573 158.964 253.553 160.182 253.323 160.857C252.633 162.939 249.703 161.924 247.693 161.064C250.513 163.767 252.773 166.522 252.573 168.254C252.493 168.932 252.123 169.452 251.533 169.719C250.453 170.202 248.423 169.572 246.193 168.494C248.523 171.079 250.023 173.547 249.043 174.954C248.833 175.254 248.423 175.637 247.703 175.757C246.103 176.017 243.213 174.692 240.463 173.107C242.793 176.079 244.723 179.367 243.853 181.344C243.473 182.222 242.723 182.667 241.903 182.667V182.662Z" fill="#292C56"/>
  // <path d="M196.158 140.844C195.298 140.844 194.833 139.814 195.418 139.172C202.63 131.247 209.603 129.507 209.903 129.434C210.433 129.309 210.973 129.637 211.103 130.174C211.233 130.709 210.903 131.249 210.363 131.379C210.263 131.404 203.645 133.102 196.898 140.517C196.7 140.734 196.43 140.844 196.158 140.844Z" fill="#292C56"/>
  // <path d="M98.1326 136.124C97.3026 136.124 96.8246 135.157 97.3526 134.499C102.678 127.844 114.99 122.707 115.513 122.492C116.025 122.279 116.608 122.522 116.818 123.034C117.028 123.544 116.785 124.129 116.275 124.339C116.153 124.389 103.918 129.497 98.9126 135.749C98.7146 135.997 98.4246 136.124 98.1326 136.124Z" fill="#292C56"/>
  // </svg>

  //     </Icon>
  //   );
}
